<template>
  <b-card class="program-update-tab-card">
    <b-row v-if="hasProgramAdminRole">
      <b-col
        cols="6"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <b-button
              variant="primary"
              style="margin-left: 10px"
              @click="showAddTraining()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
                size="16"
              />
              <span class="text-nowrap">Add Training</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <program-trainings-list
      ref="programTrainingsList"
      @edit="editTraining"
    />

    <!-- Add training to program -->
    <add-training-to-program
      v-if="hasProgramAdminRole"
      :show-modal="showModal"
      @trainingAdded="onTrainingAdded"
      @hiddenModal="hiddenModal"
    />

    <!-- Edit training -->
    <b-sidebar
      id="edit-training-right"
      title="Edit Training"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="50%"
    >
      <div>
        <edit-training
          ref="editTraining"
          :is-program-training="true"
          :training="trainingEdit"
          @trainingUpdated="onTrainingUpdated"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { BCard, BSidebar, BButton } from "bootstrap-vue";
import ProgramTrainingsList from "./ProgramTrainingsList";
import EditTraining from "@/views/apps/admin/training/EditTraining.vue";
import AddTrainingToProgram from "@/views/apps/admin/training/AddTrainingToProgram.vue";
import { mapGetters } from "vuex";

export default {

  components: {
    BCard,
    BSidebar,
    ProgramTrainingsList,
    EditTraining,
    AddTrainingToProgram,
    BButton
  },
  data() {
    return {
      trainingEdit: {},
      showModal: false,
    };
  },
  computed: {
    ...mapGetters("profile", ['isProgramAdmin']),
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.$route.params.id));
    },
  },
  methods: {
    showAddTraining() {
      this.showModal = true;
    },
    onTrainingAdded() {
      this.$refs.programTrainingsList.loadItems();
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
    onTrainingUpdated() {
      this.$refs.programTrainingsList.loadItems();
      this.showEditTraining();
    },

    editTraining(training) {
      this.trainingEdit = training;
      this.showEditTraining();
    },

    showEditTraining() {
      this.$root.$emit("bv::toggle::collapse", "edit-training-right");
    },
  },
};
</script>
<style lang="scss">
 $sidebar-header-text-color: #808080;
    .b-sidebar > .b-sidebar-header{
      flex-direction: row-reverse;
      background: #f3f3f3;
      color: $sidebar-header-text-color;
  
      #edit-training-right___title__ {
        font-size: .8em;
        flex: 2;
        text-align: left;
      }
    }
</style>